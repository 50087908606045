.account-layout {
    min-height: calc(100vh - 64px);
    margin-top: 64px;
}

.account-sider {
    height: calc(100vh - 64px);
    position: fixed;
    left: 0;
    top: 64px;
    bottom: 0;
    background: #fff;
    transition: all 0.2s;
    z-index: 999;
    box-shadow: 2px 0 8px rgba(0,0,0,0.15);
}

.account-menu {
    height: 100%;
    border-right: 0;
}

.account-main {
    min-height: calc(100vh - 64px);
    padding: 24px;
    transition: all 0.2s;
}

.account-content {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    min-height: 280px;
}

/* 折叠按钮样式 */
.menu-trigger {
    position: fixed;
    top: 80px;
    z-index: 1200;
    transition: all 0.2s;
    padding: 4px 8px;
    border-radius: 0 4px 4px 0 !important;
    box-shadow: 2px 0 8px rgba(0,0,0,0.15);
}

/* 响应式样式 */
@media (max-width: 768px) {
    .account-main {
        margin-left: 0 !important;
        padding: 12px;
    }

    .account-content {
        padding: 16px;
    }

    .menu-trigger {
        top: 70px;
    }

    .account-sider {
        box-shadow: 2px 0 8px rgba(0,0,0,0.15);
    }
} 