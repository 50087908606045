.payment-modal {
  width: 530px !important;
}

.payment-modal .ant-modal-content {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.payment-modal .ant-modal-header {
  padding: 24px 32px;
  border-bottom: none;
}

.payment-modal .ant-modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}

.payment-modal .ant-modal-body {
  padding: 0 32px;
}

.payment-modal .ant-modal-footer {
  padding: 24px 32px;
  border-top: none;
}

/* 支付金额区域 */
.payment-container .amount-section {
  background: linear-gradient(135deg, #f6f7ff 0%, #f0f5ff 100%);
  padding: 24px;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 24px;
}

.payment-container .amount-label {
  font-size: 15px;
  color: #666;
  margin-bottom: 8px;
}

.payment-container .amount-value {
  font-size: 32px;
  font-weight: 600;
  color: #1a1a1a;
  font-family: DIN, Arial, sans-serif;
}

/* 账户信息区域 */
.payment-container .account-info {
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
}

.payment-container .info-item {
  flex: 1;
  background: #f8f9fc;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.payment-container .info-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
}

.payment-container .balance-icon {
  background-color: #e6f4ff;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231890ff"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"/></svg>');
}

.payment-container .points-icon {
  background-color: #fff0f6;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23eb2f96"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"/></svg>');
}

.payment-container .info-content {
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.payment-container .info-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.payment-container .info-value {
  font-size: 16px;
  font-weight: 500;
  color: #1a1a1a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 支付方式区域 */
.payment-method {
  margin: 24px 0;
}

/* Radio 组样式 */
.payment-radio-group {
  width: 100%;
}

/* 选项容器 */
.method-options {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

/* 选项包装器 */
.method-option-wrapper {
  flex: 1;
  min-width: 0;
}

/* Radio 基础样式 */
.payment-method .ant-radio-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
}

/* 隐藏 Radio 的圆点 */
.payment-method .ant-radio {
  display: none;
}

/* 选项内容样式 */
.option-content {
  display: flex;
  align-items: center;
  gap: 16px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  padding: 16px 24px;
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
  box-sizing: border-box;
}

/* 选中状态 */
.ant-radio-wrapper-checked .option-content {
  background: #f0f5ff;
  border-color: #1890ff;
}

/* 图标样式 */
.info-icon {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 26px;
}

.balance-icon {
  background-color: #e6f4ff;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231890ff"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"/></svg>');
}

.payment-container .points-icon {
  background-color: #fff0f6;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23eb2f96"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"/></svg>');
}

.payment-container .info-content {
  flex: 1;
}

.payment-container .info-label {
  font-size: 15px;
  color: #666;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.payment-container .info-value {
  font-size: 17px;
  font-weight: 500;
  color: #1a1a1a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 按钮样式 */
.payment-modal .ant-modal-footer .ant-btn-primary {
  height: 44px;
  padding: 0 32px;
  font-size: 16px;
  border-radius: 22px;
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  border: none;
  box-shadow: 0 2px 8px rgba(24, 144, 255, 0.35);
}

.payment-modal .ant-modal-footer .ant-btn-default {
  height: 44px;
  padding: 0 32px;
  font-size: 16px;
  border-radius: 22px;
}

/* 弹窗宽度响应式 */
.payment-modal {
  width: 530px !important;
}

/* 支付方式选择器响应式布局 */
.method-options {
  display: flex;
  gap: 16px;
}

/* 在小屏幕下改为垂直排列 */
@media screen and (max-width: 576px) {
  .payment-modal {
    width: 90vw !important;
    margin: 0 auto;
  }

  .method-options {
    flex-direction: column;
  }

  .method-option .option-content {
    padding: 12px 16px;
  }

  /* 调整小屏幕下的其他间距 */
  .payment-modal .ant-modal-body {
    padding: 0 16px;
  }

  .payment-modal .ant-modal-header {
    padding: 16px;
  }

  .payment-modal .ant-modal-footer {
    padding: 16px;
  }
}

/* 确保内容不重叠 */
.method-option {
  flex: 1;
  margin: 0 !important;
}

.method-option .option-content {
  display: flex;
  align-items: center;
  gap: 12px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  padding: 16px 20px;
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
} 