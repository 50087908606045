.trial-modal .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
}

.trial-modal .ant-modal-header {
    padding: 24px 32px;
    border-bottom: none;
}

.trial-modal .ant-modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #1a1a1a;
}

.trial-modal .ant-modal-body {
    padding: 0 32px 24px;
}

.trial-modal .ant-modal-footer {
    padding: 16px 32px 24px;
    border-top: none;
}

/* 试用信息区域 */
.trial-container .trial-info {
    display: flex;
    align-items: center;
    gap: 16px;
    background: linear-gradient(135deg, #f0f5ff 0%, #e6f7ff 100%);
    padding: 24px;
    border-radius: 12px;
    margin-bottom: 24px;
}

.trial-info .info-icon {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background-color: #1890ff;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"/></svg>');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 28px;
    flex-shrink: 0;
}

.trial-info .info-content {
    flex: 1;
}

.trial-info .info-title {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 4px;
}

.trial-info .info-desc {
    font-size: 14px;
    color: #666;
}

/* 试用须知区域 */
.trial-container .trial-notice {
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    padding: 20px 24px;
}

.trial-notice h4 {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0 0 16px 0;
}

.trial-notice ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.trial-notice li {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    margin-bottom: 12px;
    line-height: 1.5;
}

.trial-notice li:last-child {
    margin-bottom: 0;
}

.trial-notice .dot {
    width: 6px;
    height: 6px;
    background: #1890ff;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
}

/* 按钮样式 */
.trial-modal .ant-modal-footer .ant-btn-primary {
    height: 40px;
    padding: 0 32px;
    font-size: 15px;
    border-radius: 20px;
    background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
    border: none;
    box-shadow: 0 2px 8px rgba(24, 144, 255, 0.35);
}

.trial-modal .ant-modal-footer .ant-btn-default {
    height: 40px;
    padding: 0 32px;
    font-size: 15px;
    border-radius: 20px;
} 