.footer {
    background: linear-gradient(to right, #1a1a1a, #2d2d2d);
    color: #fff;
    padding: 80px 0 20px;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Logo 区域样式 */
.footer-brand {
    margin-bottom: 30px;
}

.footer-logo {
    height: 40px;
    margin-bottom: 20px;
}

.footer-description {
    color: #b0b0b0;
    line-height: 1.6;
    margin-bottom: 24px;
}

/* 社交媒体图标 */
.footer-social {
    display: flex;
    gap: 16px;
}

.footer-social a {
    color: #fff;
    font-size: 20px;
    transition: all 0.3s ease;
}

.footer-social a:hover {
    color: #1890ff;
    transform: translateY(-3px);
}

/* 链接区域样式 */
.footer-section {
    margin-bottom: 30px;
}

.footer-section h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
}

.footer-section h3::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 30px;
    height: 2px;
    background: #1890ff;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-section ul li {
    margin-bottom: 12px;
}

.footer-section ul li a {
    color: #b0b0b0;
    text-decoration: none;
    transition: all 0.3s ease;
}

.footer-section ul li a:hover {
    color: #1890ff;
    padding-left: 5px;
}

/* 联系方式样式 */
.footer-contact li {
    display: flex;
    gap: 8px;
    color: #b0b0b0;
}

.footer-contact li strong {
    color: #fff;
    min-width: 60px;
}

.footer-contact li a {
    color: #b0b0b0;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-contact li a:hover {
    color: #1890ff;
}

/* 分割线样式 */
.footer-divider {
    background: rgba(255, 255, 255, 0.1);
    margin: 40px 0 20px;
}

/* 底部版权区域 */
.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.copyright {
    color: #b0b0b0;
    font-size: 14px;
}

.footer-links {
    display: flex;
    gap: 24px;
}

.footer-links a {
    color: #b0b0b0;
    font-size: 14px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #1890ff;
}

/* 响应式样式 */
@media (max-width: 768px) {
    .footer {
        padding: 40px 0 20px;
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
        gap: 16px;
    }

    .footer-links {
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
    }
}

@media (max-width: 576px) {
    .footer-content {
        padding: 0 15px;
    }

    .footer-section h3 {
        font-size: 16px;
    }

    .footer-social {
        justify-content: center;
    }
} 