.container {
  min-height: 500px;
  border-radius: 8px;
}

.tabs {
  margin-top: -12px;
}

.tabs :global(.ant-tabs-nav) {
  margin-bottom: 24px;
}

.verificationContainer {
  padding: 24px 0;
}

.steps {
  max-width: 800px;
  margin: 0 auto 40px;
}

.stepContentWrapper {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  max-width: 400px;
  width: 100%;
}

.submitButton {
  margin-top: 24px;
}

.stepContent {
  text-align: center;
  padding: 32px;
}

.loadingIcon {
  font-size: 48px;
  color: #1890ff;
  animation: rotate 1.5s infinite linear;
}

.successIcon {
  font-size: 72px;
  color: #52c41a;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.passwordContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 400px;
  padding: 24px;
}

.formContainer {
  width: 100%;
  max-width: 400px;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.submitButton {
  margin-top: 32px;
}

.submitBtn {
  height: 40px;
  font-size: 16px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.submitBtn:hover {
  opacity: 0.9;
}

/* 防止按钮闪动 */
.submitBtn:focus {
  outline: none;
}

/* 响应式样式 */
@media (max-width: 768px) {
  .container {
    margin: 0 -16px;
    border-radius: 0;
  }

  .steps {
    padding: 0 16px;
  }

  .stepContentWrapper {
    margin: 0 16px;
  }

  .passwordContainer {
    padding: 16px;
  }

  .formContainer {
    padding: 16px;
  }
} 