/* 使用 ps- 前缀避免样式冲突 */
.ps-container {
  padding: 20px;
  background: #f5f5f5;
  min-height: calc(100vh - 64px);
  margin-top: 64px;
}

.ps-main {
  display: flex;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.ps-left {
  flex: 3;
  background: white;
  border-radius: 8px;
  padding: 20px;
}

.ps-right {
  flex: 1;
  background: white;
  border-radius: 8px;
  padding: 20px;
}

.ps-header {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.ps-title {
  font-weight: 500;
}

.ps-feedback {
  margin-left: 20px;
  color: #666;
  font-size: 14px;
}

.ps-feedback-link {
  color: #1a73e8;
  text-decoration: none;
}

.ps-banner {
  position: absolute;
  right: 0;
  background: #4355f9;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
}

.ps-sale-tag {
  background: rgba(255,255,255,0.2);
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
}

.ps-duration {
  margin: 20px 0;
}

.ps-duration-btns {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.ps-duration-btn {
  padding: 8px 24px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
}

.ps-duration-btn.ps-active {
  background: #1a73e8;
  color: white;
  border-color: #1a73e8;
}

.ps-countries-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-top: 15px;
}

.ps-country-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;    
  width: 150px;

  margin: 5px;
  box-shadow: 0 0 2px 1px #0003;
}

.ps-country-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.ps-country-total {
  color: #666;
  font-size: 12px;
  margin: 5px 0;
}

.ps-price {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.ps-current-price {
  color: #1a73e8;
  font-weight: bold;
}

.ps-original-price {
  text-decoration: line-through;
  color: #999;
  font-size: 12px;
}

.ps-discount {
  background: #ff5722;
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
}

.ps-order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ps-countries-list {
  height: 500px;
  overflow: hidden;
  border: 1px solid #ddd;
}

.ps-country-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
  box-shadow: 0 0 2px 1px #0003;
}

.ps-clear {
  color: #1a73e8;
  background: none;
  border: none;
  cursor: pointer;
}

.ps-service-title {
  font-weight: 500;
  margin-bottom: 15px;
}

.ps-service-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ps-service-item > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ps-service-item > div:last-child {
  margin-bottom: 0;
}



.ps-service-item > div .ant-input,
.ps-service-item > div select {
  flex: 1;
  height: 32px;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.ps-service-item > div .ant-input:hover,
.ps-service-item > div select:hover {
  border-color: #40a9ff;
}

.ps-service-item > div .ant-input:focus,
.ps-service-item > div select:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  outline: none;
}

.ps-service-item > div select {
  background-color: white;
}

.ps-region-tip {
  color: #999;
}

.ps-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  font-weight: 500;
}

.ps-amount {
  color: #1a73e8;
  font-size: 18px;
}

.ps-buy-btn {
  width: 100%;
  padding: 12px;
  background: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.ps-security {
  text-align: center;
  color: #4caf50;
  margin: 15px 0;
}

.ps-payment {
  text-align: center;
  color: #666;
  margin-top: 20px;
}

.ps-payment-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

/* 响应式样式 */
@media screen and (max-width: 768px) {
  .ps-container {
    padding: 10px;
    margin-top: 56px; /* 调整移动端导航栏高度 */
  }

  .ps-main {
    flex-direction: column;
    gap: 10px;
  }

  .ps-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .ps-banner {
    position: static;
    margin-top: 10px;
    font-size: 14px;
  }

  .ps-countries-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }



  .ps-duration-btns {
    flex-wrap: wrap;
  }

  .ps-duration-btn {
    flex: 1;
    min-width: 100px;
    text-align: center;
    padding: 8px 12px;
  }

  .ps-feedback {
    margin-left: 0;
  }

  .ps-price {
    flex-wrap: wrap;
  }

  /* 调整右侧订单信息面板 */
  .ps-right {
    margin-bottom: 20px;
  }

  /* 调整支付图标大 */
  .ps-payment-icons {
    flex-wrap: wrap;
  }

  .ps-countries-list {
    height: 400px; /* 在移动端可以适当减小高度 */
  }
}

/* 更小屏幕的优化 */
@media screen and (max-width: 480px) {
  .ps-countries-grid {
    grid-template-columns: 1fr;
  }

  .ps-country-card {
    margin-bottom: 10px;
  }

  .ps-duration-btns {
    flex-direction: column;
  }

  .ps-duration-btn {
    width: 100%;
  }

  .ps-banner {
    font-size: 12px;
    text-align: center;
  }

  .ps-payment-text {
    font-size: 12px;
  }
}

/* 确保内容不会被底部导航遮挡 */
@media screen and (max-width: 768px) {
  .ps-container {
    padding-bottom: 80px;
  }
}

/* Form 样式 */
.ps-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;  /* 靠右对齐 */
  width: 100%;
}

.ps-form-item {
  width: 100%;  /* 让表单项占满容器宽度 */
}

/* Form Label 样式 */
.ps-form .ant-form-item-label {
  text-align: left;  /* 标签左对齐 */
  padding: 0;
}

/* Select 组件样式 */
.ps-form .ant-select {
  width: 100%;
}

/* 按钮组样式 */
.ps-duration-btns {
  display: flex;
  gap: 10px;
  width: 100%;
}

.ant-input-number-group-addon .anticon {
  cursor: pointer;
  transition: color 0.3s;
}

.ant-input-number-group-addon .anticon:hover {
  color: #1890ff;
}

/* 只添加自定义账号密码的新样式 */
.ps-service-item .custom-auth {
  display: flex;
  flex-direction: column;
  gap: 15px;  /* 账号和密码之间的间距 */
  width: 100%;
}

.ps-service-item .custom-auth > div {
  display: flex;
  align-items: center;
  width: 100%;
}

.ps-service-item .custom-auth span {
  color: #666;
  font-size: 14px;
  width: 70px;  /* 标签固定宽度 */
  flex-shrink: 0;  /* 防止标签被压缩 */
}

.ps-service-item .custom-auth .ant-input,
.ps-service-item .custom-auth select {
  flex: 1;  /* 输入框占据剩余空间 */
}

.ps-countries-list {
  height: 500px; /* 或者其他合适的高度 */
  overflow: hidden;
  display: flex;
  height: 500px;
  flex-wrap: wrap;
  padding: 10px;
  flex-direction: row;
  overflow: auto;
  align-content: flex-start;
  border: 1px solid #ddd;
}

.ps-country-card {
  padding: 10px;
  margin: 5px 0;
}

/* 订单信息容器 */
.ps-order {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  flex-shrink: 0;
}

/* 清空按钮 */
.ps-order-header {
  text-align: right;
  margin-bottom: 20px;
}

.ps-clear {
  color: #999;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}

/* 信息区域 */
.ps-service {
  margin-top: 15px;
}

.ps-service-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

/* 服务项目 */
.ps-service-item {
  margin-bottom: 15px;
}

.ps-service-item > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}


/* 总价区域 */
.ps-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding-top: 15px;
  border-top: 1px solid #f0f0f0;
}

.ps-amount {
  color: #ff4d4f;
  font-size: 20px;
  font-weight: 500;
}

/* 购买按钮 */
.ps-buy-btn {
  width: 100%;
  height: 40px;
  background: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;
}

.ps-buy-btn:hover {
  background: #40a9ff;
}

.ps-security {
  text-align: center;
  color: #52c41a;
  margin-top: 15px;
  font-size: 14px;
}

/* 自定义认证部分样式 */
.custom-auth {
  display: flex;
  flex-direction: column;
  gap: 15px;  /* 账号和密码之间的间距 */
  width: 100%;
}

.custom-auth > div {
  display: flex;
  align-items: center;
  width: 100%;
}

.custom-auth span {
  color: #666;
  font-size: 14px;
  width: 70px;  /* 标签固定宽度 */
  flex-shrink: 0;  /* 防止标签被压缩 */
}

.custom-auth .ant-input,
.custom-auth select {
  flex: 1;  /* 输入框占据剩余空间 */
}

.buy-btn {
  width: 100%;
  height: 40px;
  background: linear-gradient(to right, #ff8c00, #ff6b00);
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(255, 140, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.buy-btn:hover {
  background: linear-gradient(to right, #ff9d1c, #ff7b1c);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(255, 140, 0, 0.4);
}

.buy-btn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(255, 140, 0, 0.2);
}

/* 添加到现有的 CSS 文件中 */
.payment-info {
  padding: 16px 0;
}

.amount-row {
  font-size: 18px;
  margin-bottom: 20px;
}

.amount-row .highlight {
  color: #f5222d;
  font-weight: bold;
}

.balance-info {
  margin-bottom: 20px;
  color: #666;
}

.payment-method {
  margin-top: 16px;
}