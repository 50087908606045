.container {
    padding: 24px;
    background: #fff;
    min-height: 500px;
}

.tableHeader {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectedInfo {
    color: #1890ff;
    font-size: 14px;
    margin-top: 8px;
}

/* 响应式样式 */
@media (max-width: 768px) {
    .container {
        padding: 16px;
        margin: 0 -16px;
    }
}

.tableHeader {
    padding: 0 16px;
}