/* 添加在文件最顶部 */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
}

/* 所有容器类的通用样式 */
.container, 
.hero-content,
.intro,
.advantages,
.pricing,
.testimonials,
.cta-section {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    padding-left: 20px;
    padding-right: 20px;
}

/* 网格布局容器修复 */
.advantages-grid,
.pricing-cards,
.testimonials-grid {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    padding: 0;
}

/* 修复 hero 区域 */
.hero-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    gap: 40px;
}

@media (max-width: 768px) {
    /* 确保所有容器在移动端都有合适的内边距 */
    .container,
    .hero-content,
    .intro,
    .advantages,
    .pricing,
    .testimonials,
    .cta-section {
        padding-left: 15px;
        padding-right: 15px;
    }

    /* 修复网格布局 */
    .advantages-grid,
    .pricing-cards,
    .testimonials-grid {
        grid-template-columns: 1fr;
    }

    /* 确保图片不会溢出容器 */
    img {
        max-width: 100%;
        height: auto;
    }

    /* 修复表格溢出问题 */
    .ant-table-wrapper {
        width: 100%;
        overflow-x: auto;
    }

    /* 修复按钮和输入框宽度 */
    .new-button,
    input,
    button {
        max-width: 100%;
    }
}

/* 修复 intro 区域 */
.intro-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;
}

.intro-items li {
    flex: 1 1 280px;
    min-width: 0; /* 防止 flex 项目溢出 */
}

/* 修复 pricing 卡片 */
.pricing-card {
    width: 100%;
    max-width: 100%;
}

/* 修复 testimonials 卡片 */
.testimonial-card {
    width: 100%;
    max-width: 100%;
}

.hero-left {
    flex: 1;
}

.hero-right {
    flex: 1;
}

.hero-left-top {
    background: #1bcd970f;
    border: 1px solid #1bcd97;
    border-radius: 4px;
    box-sizing: border-box;
    height: 40px;
    padding-top: 9px;
    text-align: center;
    width: 320px;
}

.hero-left h1 {
    color: #1f2328;
    font-family: Rubik_Medium;
    font-size: 44px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
}

.itms {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.list-item {
    margin: 10px 0;
}

.list-item-content {
    display: flex;
    align-items: center;
}

.new-button {
    align-items: center;
    background: #0056fb;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    height: 48px;
    justify-content: center;
    transition: all .3s;
    width: 240px;
}

.new-button span {
    color: #fff;
    font-family: Rubik_Medium;
    font-size: 16px;
    font-weight: 500;
}

.hero-right img {
    width: 100%;
    height: auto;
    max-width: 500px;
    display: block;
}

/* 响应式设计 */
@media (max-width: 1024px) {
    .hero-content {
        flex-direction: column;
        text-align: center;
        padding: 40px 20px;
    }

    .hero-left {
        padding-right: 0;
        margin-bottom: 40px;
    }

    .hero-right {
        width: 100%;
    }

    .hero-right img {
        max-width: 80%;
        margin: 0 auto;
    }
}

.search-bar {
    display: flex;
    justify-content: center;
}



/* intro */

.intro {
    display: flex;
    justify-content: center;
    padding: 60px 0;
    max-width: 1200px;
    margin: 0 auto;
}

.intro-items {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
}

.intro-items li {
    background: #f7f9ff;
    border-radius: 8px;
    box-sizing: border-box;
    flex: 0 0 25%;
    padding: 22px 24px 24px;
}

.section-title {
    text-align: center;
    font-size: 2.5rem;
    margin: 4rem 0 2rem;
    color: #1a1a1a;
}

/* 优势展示区域样式 */
.advantages {
    padding: 4rem 0;
    background: #ffffff;
}

.advantages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.advantage-card {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    background: #f8f9fa;
    transition: transform 0.3s ease;
}

.advantage-card:hover {
    transform: translateY(-5px);
}

.advantage-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

/* 价格方案区域样式 */
.pricing {
    padding: 4rem 0;
    background: #f7f9ff;
}

.pricing-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.pricing-card {
    background: white;
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.pricing-card.featured {
    transform: scale(1.05);
    border: 2px solid #4a90e2;
}

.pricing-card:hover {
    transform: translateY(-10px);
}

.pricing-header {
    text-align: center;
    margin-bottom: 2rem;
}

.price .amount {
    font-size: 2.5rem;
    font-weight: bold;
    color: #4a90e2;
}

.features {
    list-style: none;
    padding: 0;
    margin: 2rem 0;
}

.features li {
    padding: 0.5rem 0;
    text-align: center;
}

.pricing-button {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    background: #4a90e2;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease;
}

.pricing-button:hover {
    background: #357abd;
}

/* 客户评价区域样式 */
.testimonials {
    padding: 4rem 0;
    background: #ffffff;
}

.testimonials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.testimonial-card {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 2rem;
    transition: transform 0.3s ease;
}

.testimonial-card:hover {
    transform: translateY(-5px);
}

.testimonial-content {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}

.testimonial-author {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.author-name {
    font-weight: bold;
}

.author-company {
    color: #6c757d;
}

/* 添加底部CTA区域 */
.cta-section {
    padding: 4rem 0;
    background: #ffffff;
    text-align: center;
}

.cta-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #1a1a1a;
}

.cta-section p {
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 2rem;
}

.cta-button {
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    background: #4a90e2;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease;
}

.cta-button:hover {
    background: #357abd;
}

/* 更新 hero 区域响应式样式 */
@media (max-width: 768px) {
    .hero-content {
        flex-direction: column;
        padding: 20px;
        gap: 20px;
    }

    .hero-left {
        text-align: center;
    }

    .hero-left-top {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
    }

    .hero-left h1 {
        font-size: 32px;
        line-height: 40px;
    }

    .hero-right img {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }

    .search-bar {
        justify-content: center;
    }

    /* 修复 intro 区域 */
    .intro {
        padding: 30px 15px;
    }

    .intro-items {
        flex-direction: column;
        gap: 15px;
    }

    .intro-items li {
        width: 100%;
        flex: none;
    }

    /* 修复 pricing 区域 */
    .pricing-cards {
        padding: 0 15px;
    }

    .pricing-card {
        margin-bottom: 20px;
    }

    .pricing-card.featured {
        transform: none;
    }

    /* 修复 testimonials 区域 */
    .testimonials-grid {
        padding: 0 15px;
    }

    .testimonial-card {
        margin-bottom: 20px;
    }

    /* 调整按钮大小 */
    .new-button {
        width: 100%;
        max-width: 240px;
        margin: 0 auto;
    }
}

/* 更小屏幕的额外优化 */
@media (max-width: 480px) {
    .hero-left h1 {
        font-size: 28px;
        line-height: 36px;
    }

    .section-title {
        font-size: 24px;
        margin: 2rem 0 1rem;
    }

    .advantages-grid {
        grid-template-columns: 1fr;
        padding: 0 15px;
    }
}

.tooltip {
    position: relative;
}

.tooltip::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 12px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    margin-bottom: 8px;
}

.tooltip:hover::after {
    visibility: visible;
    opacity: 1;
}

.new-button-wrapper {
    position: relative;
    display: inline-block;
}

.permanent-tooltip {
    position: absolute;
    bottom: calc(100% + 15px);
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border: 1px solid #1bcd97;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 14px;
    color: #333;
    white-space: nowrap;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.permanent-tooltip::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background: #fff;
    border-right: 1px solid #1bcd97;
    border-bottom: 1px solid #1bcd97;
}

.floating-service {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
}

.service-button {
    background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
    border: none;
    border-radius: 50px;
    padding: 12px 24px;
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(79, 70, 229, 0.3);
    transition: transform 0.2s, box-shadow 0.2s;
}

.service-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(79, 70, 229, 0.4);
}

.service-icon {
    font-size: 20px;
}

.service-text {
    font-size: 15px;
    font-weight: 500;
}

.service-popup {
    position: absolute;
    bottom: 70px;
    right: 0;
    width: 300px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: all 0.3s ease;
}

.service-popup.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
}

.popup-header h4 {
    margin: 0;
    color: #333;
    font-size: 16px;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #666;
    cursor: pointer;
    padding: 0;
}

.popup-content {
    padding: 20px;
}

.qq-info {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 15px;
    padding: 12px;
    background: #f8f9ff;
    border-radius: 8px;
}

.qq-icon {
    background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
    color: white;
}

.qq-number {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.copy-button {
    background: none;
    border: none;
    color: #4a90e2;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 8px;
    border-radius: 6px;
}