.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 0 50px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  height: 64px;
}

.logo {
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.logo img {
  height: 32px;
  object-fit: contain;
}

.menu {
  flex: 1;
  border-bottom: none !important;
  background: transparent;
}

.menu .ant-menu-item {
  padding: 0 20px;
}

.menu .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}

.button-group {
  margin-left: auto;
  margin-right: 50px;
}

.user-info, .auth-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.welcome-text {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.auth-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.user-info span {
  color: rgba(0, 0, 0, 0.85);
}

.menu-button {
  display: none;
  font-size: 20px;
  margin-left: 16px;
  cursor: pointer;
}

.ant-drawer .ant-menu {
  border-right: none;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0 16px;
  }

  .menu {
    display: none;
  }

  .menu-button {
    display: block;
  }

  .button-group {
    width: auto;
    margin-right: 40px;
  }

  .user-info, .auth-buttons {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .welcome-text {
    text-align: center;
  }

  .user-info .ant-btn,
  .auth-buttons .ant-btn {
    width: 100%;
  }
}

.ant-menu-horizontal {
  line-height: 64px;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  display: none;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 2px solid transparent;
}

.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: 2px solid #1890ff;
}

.login-button.ant-btn {
  background-color: #f0f0f0 !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.login-button.ant-btn:hover {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.login-button {
  color: #000;
  margin-right: 16px;
}

.login-button:hover {
  color: #1890ff;
}

.member-button.ant-btn {
  background-color: #f0f0f0 !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.member-button.ant-btn:hover {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.user-info {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 0;
}

.user-info > * {
    flex-shrink: 0;
}

.welcome-text {
    margin-right: 8px;
    white-space: nowrap;
}

.nav-link {
    display: inline-block;
    margin-right: 8px;
}

.member-button,
.logout-button {
    padding: 4px 8px;
    white-space: nowrap;
}