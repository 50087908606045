.recharge-activity-page {
    min-height: calc(100vh - 64px);
    margin-top: 64px;
    background: #0a0b1a;
    position: relative;
    overflow: hidden;
}

/* 动画背景 */
.animated-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.light-beam {
    position: absolute;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, 
        rgba(41, 98, 255, 0.1),
        rgba(0, 209, 255, 0.1),
        rgba(41, 98, 255, 0.1));
    animation: rotate 20s linear infinite;
}

.particles {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: 
        radial-gradient(circle, #ffffff 1px, transparent 1px),
        radial-gradient(circle, #ffffff 1px, transparent 1px);
    background-size: 50px 50px;
    background-position: 0 0, 25px 25px;
    opacity: 0.1;
    animation: sparkle 4s ease-in-out infinite;
}

/* 主要内容 */
.activity-content {
    position: relative;
    z-index: 1;
    padding: 60px 20px;
    max-width: 1400px;
    margin: 0 auto;
}

.hero-section {
    text-align: center;
    color: white;
    margin-bottom: 60px;
}

.hero-icon {
    font-size: 48px;
    color: #00d1ff;
    margin-bottom: 20px;
}

.hero-section h1 {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 24px;
    background: linear-gradient(to right, #2962ff, #00d1ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 20px;
}

.highlight {
    color: #00d1ff;
}

.divider {
    width: 6px;
    height: 6px;
    background: #00d1ff;
    border-radius: 50%;
}

.plans-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 30px;
    margin-bottom: 60px;
}

.plan-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.plan-card:hover {
    transform: translateY(-10px);
    border-color: rgba(0, 209, 255, 0.3);
}

.plan-tag {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 4px 12px;
    background: rgba(0, 209, 255, 0.2);
    border-radius: 20px;
    color: #00d1ff;
    font-size: 14px;
}

.plan-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.plan-amount {
    color: white;
}

.currency {
    font-size: 24px;
    opacity: 0.8;
}

.number {
    font-size: 40px;
    font-weight: 600;
}

.bonus-arrow {
    color: #00d1ff;
    font-size: 24px;
}

.gift-icon {
    font-size: 28px;
}

.bonus-amount {
    text-align: right;
}

.bonus-value {
    font-size: 36px;
    font-weight: 600;
    color: #00d1ff;
}

.bonus-label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
}

.card-glow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 50% 0%, 
        rgba(0, 209, 255, 0.1) 0%,
        transparent 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.plan-card:hover .card-glow {
    opacity: 1;
}

.action-section {
    text-align: center;
    margin-bottom: 60px;
}

.recharge-button {
    height: 50px;
    padding: 0 40px;
    font-size: 18px;
    border-radius: 25px;
    background: linear-gradient(45deg, #2962ff, #00d1ff);
    border: none;
    box-shadow: 0 5px 20px rgba(0, 209, 255, 0.3);
    transition: all 0.3s ease;
}

.recharge-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 25px rgba(0, 209, 255, 0.4);
}

.rules-section {
    max-width: 1000px;
    margin: 0 auto;
}

.rules-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 40px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.rules-card h3 {
    color: white;
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
}

.rules-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
}

.rule-item {
    text-align: center;
}

.rule-number {
    font-size: 24px;
    font-weight: 600;
    color: #00d1ff;
    margin-bottom: 12px;
}

.rule-text {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    line-height: 1.5;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sparkle {
    0%, 100% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.2;
    }
}

/* 响应式调整 */
@media (max-width: 768px) {
    .activity-content {
        padding: 40px 16px;
    }

    .hero-section h1 {
        font-size: 36px;
    }

    .hero-subtitle {
        font-size: 16px;
    }

    .plan-card {
        padding: 20px;
    }

    .number {
        font-size: 32px;
    }

    .bonus-value {
        font-size: 28px;
    }

    .rules-card {
        padding: 30px 20px;
    }
} 