.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modal-content {
    background: white;
    padding: 2.8rem 2.5rem;
    border-radius: 16px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    position: relative;
    animation: slideUp 0.4s ease;
    box-sizing: border-box;
}

.close-button {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    background: rgba(0, 0, 0, 0.05);
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 1.2rem;
    cursor: pointer;
    color: #666;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button:hover {
    background: rgba(0, 0, 0, 0.1);
    transform: rotate(90deg);
}

.auth-tabs {
    display: flex;
    margin: -0.3rem -0.3rem 2rem -0.3rem;
    background: rgba(0, 0, 0, 0.02);
    padding: 0.4rem;
    border-radius: 8px;
}

.tab {
    flex: 1;
    padding: 0.6rem;
    font-size: 0.9rem;
    border: none;
    background: none;
    color: #666;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 6px;
}

.tab.active {
    background: white;
    color: #1890ff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.auth-form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;
}

.input-wrapper {
    position: relative;
    height: 38px;
    transition: transform 0.3s;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.input-wrapper:focus-within {
    transform: translateY(-2px);
}

.input-with-icon {
    width: 100%;
    height: 38px;
    padding: 0 1rem 0 2.4rem;
    border: 1px solid #eee;
    border-radius: 8px;
    font-size: 0.9rem;
    transition: all 0.3s;
    box-sizing: border-box;
}

.input-with-icon:focus {
    border-color: #1890ff;
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.1);
    outline: none;
}

.input-icon {
    position: absolute;
    left: 0.8rem;
    color: #bbb;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    pointer-events: none;
}

.input-wrapper:focus-within .input-icon {
    color: #1890ff;
}

.captcha-container {
    display: flex;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.captcha-container input {
    flex: 1;
    height: 38px;
    padding: 0 1rem;
    border: 1px solid #eee;
    border-radius: 8px;
    font-size: 0.9rem;
    box-sizing: border-box;
    line-height: 38px;
}

.captcha-container input:focus {
    border-color: #1890ff;
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.1);
    outline: none;
}

.captcha-image {
    height: 38px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s;
    border: 1px solid #eee;
}

.captcha-image:hover {
    transform: scale(1.02);
}

button[type="submit"] {
    width: 100%;
    height: 38px;
    background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 1.2rem;
}

button[type="submit"]:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
}

button[type="submit"]:active {
    transform: translateY(0);
} 