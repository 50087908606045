.container {
  padding: 24px;
  background-color: #f5f7fa;
  min-height: 100%;
}

.overviewCard {
  margin-bottom: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.overviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
}

.overviewTitle h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #1a1a1a;
}

.overviewTitle p {
  color: #666;
  margin: 0;
  font-size: 14px;
}

.copyButton {
  padding: 0 24px;
  height: 40px;
}

.statsRow {
  margin: 0 -12px;
}

.statsCard {
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}

.statistic :global(.ant-statistic-title) {
  color: #666;
  font-size: 14px;
  margin-bottom: 12px;
}

.statistic :global(.ant-statistic-content) {
  color: #1a1a1a;
  font-size: 28px;
  font-weight: 600;
}

.promotionLink {
  height: 100%;
}

.linkTitle {
  color: #666;
  font-size: 14px;
  margin-bottom: 12px;
}

.link {
  background-color: #fff;
  padding: 12px;
  border-radius: 6px;
  color: #1a1a1a;
  font-size: 14px;
  word-break: break-all;
  border: 1px solid #e6e6e6;
}

.recordsCard {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.tabs :global(.ant-tabs-nav) {
  margin-bottom: 24px;
}

.tabs :global(.ant-tabs-tab) {
  font-size: 16px;
  padding: 12px 0;
}

.statusSuccess {
  color: #52c41a;
  font-weight: 500;
}

.statusPending {
  color: #faad14;
  font-weight: 500;
}

/* 响应式样式 */
@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .overviewHeader {
    flex-direction: column;
    gap: 16px;
  }

  .copyButton {
    width: 100%;
  }

  .statsCard {
    padding: 16px;
    margin-bottom: 16px;
  }

  .statistic :global(.ant-statistic-content) {
    font-size: 24px;
  }

  .link {
    font-size: 13px;
    padding: 8px;
  }
} 