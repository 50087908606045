.register-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.register-box {
    display: flex;
    max-width: 1000px;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.register-left {
    width: 55%;
    padding: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: url('https://images.unsplash.com/photo-1707343848655-a134936c4081') center/cover;
}

.register-left::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.7) 100%);
}

.left-content {
    position: relative;
    z-index: 1;
    color: #fff;
}

.brand-logo {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 60px;
}

.welcome-text {
    font-size: 56px;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 30px;
    background: linear-gradient(to right, #fff, #e0e0e0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.welcome-desc {
    font-size: 18px;
    line-height: 1.8;
    opacity: 0.9;
    max-width: 500px;
}

.register-right {
    width: 45%;
    padding: 80px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-header {
    margin-bottom: 50px;
}

.form-title {
    font-size: 36px;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 15px;
}

.form-subtitle {
    font-size: 16px;
    color: #666;
}

.form-group {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 15px;
}

.form-label {
    min-width: 80px;
    font-size: 15px;
    color: #1a1a1a;
    font-weight: 500;
    text-align: right;
    margin: 0;
}

.form-input {
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    background: #f8f9ff;
    border: 2px solid transparent;
    border-radius: 16px;
    padding: 0 25px;
    font-size: 16px;
    color: #1a1a1a;
    transition: all 0.3s ease;
}

.form-input:focus {
    background: #fff;
    border-color: #000;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.register-button {
    height: 60px;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
}

.register-button:hover {
    background: #1a1a1a;
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.terms {
    margin-top: 25px;
    text-align: center;
    font-size: 14px;
    color: #666;
}

.terms a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

.login-link {
    margin-top: 30px;
    text-align: center;
    font-size: 15px;
    color: #666;
}

.login-link a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
    margin-left: 5px;
}

@media (max-width: 1200px) {
    .register-box {
        max-width: 1000px;
    }
    
    .register-left,
    .register-right {
        padding: 60px;
    }
    
    .welcome-text {
        font-size: 48px;
    }
}

@media (max-width: 992px) {
    .register-left {
        display: none;
    }
    
    .register-right {
        width: 100%;
    }
}

/* 错误状态 */
.form-input.error {
    border-color: #ff3b3b;
    background: #fff8f8;
}

.error-message {
    color: #ff3b3b;
    font-size: 13px;
    margin-top: 8px;
}

.get-code-button {
    height: 60px;
    padding: 0 25px;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 16px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
    flex-shrink: 0;
}

.get-code-button:hover {
    background: #1a1a1a;
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.captcha-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.captcha-content {
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.3s ease;
}

.captcha-header {
    text-align: center;
    margin-bottom: 20px;
}

.captcha-title {
    font-size: 20px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 10px;
}

.captcha-image {
    width: 100%;
    height: 100px;
    background: #f5f5f5;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.captcha-input {
    width: 100%;
    height: 50px;
    background: #f8f9ff;
    border: 2px solid transparent;
    border-radius: 12px;
    padding: 0 20px;
    font-size: 16px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.captcha-input:focus {
    border-color: #000;
    outline: none;
}

.captcha-buttons {
    display: flex;
    gap: 15px;
}

.captcha-button {
    flex: 1;
    height: 50px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.captcha-confirm {
    background: #000;
    color: #fff;
    border: none;
}

.captcha-confirm:hover {
    background: #1a1a1a;
    transform: translateY(-2px);
}

.captcha-cancel {
    background: #fff;
    color: #666;
    border: 2px solid #eee;
}

.captcha-cancel:hover {
    border-color: #ddd;
    color: #333;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* 添加媒体查询，处理移动端显示 */
@media screen and (max-width: 768px) {
    .register-container {
        padding: 0;
        min-height: 100vh;
        align-items: flex-start;
        background-color: #fff;
    }

    .register-box {
        flex-direction: column;
        max-width: 100%;
        box-shadow: none;
        border-radius: 0;
        background: transparent;
    }

    .register-left {
        display: none;
    }

    .register-right {
        width: 100%;
        padding: 24px 16px;
        background: transparent;
    }

    .form-header {
        margin-bottom: 24px;
        text-align: center;
    }

    .form-title {
        font-size: 24px;
        margin-bottom: 8px;
    }

    .form-subtitle {
        font-size: 14px;
    }

    .form-group {
        flex-direction: column;
        gap: 6px;
        margin-bottom: 16px;
    }

    .form-label {
        min-width: unset;
        text-align: left;
        font-size: 14px;
        color: #333;
    }

    .form-input {
        height: 44px;
        font-size: 14px;
        padding: 0 12px;
        border-radius: 8px;
    }

    /* 验证码按钮相关样式优化 */
    .form-group.with-code {
        position: relative;
        display: flex;
        flex-direction: row !important; /* 强制水平排列 */
        gap: 8px;
    }

    .form-group.with-code .form-input {
        flex: 1;
        padding-right: 12px; /* 恢复正常内边距 */
        width: auto; /* 让输入框自适应宽度 */
    }

    .get-code-button {
        position: static; /* 取消绝对定位 */
        width: auto; /* 自适应宽度 */
        height: 44px; /* 与输入框同高 */
        padding: 0 15px;
        font-size: 13px;
        border-radius: 8px;
        white-space: nowrap;
        transform: none;
        flex-shrink: 0; /* 防止按钮被压缩 */
        min-width: 100px; /* 设置最小宽度 */
    }

    .get-code-button:hover {
        transform: none; /* 移除悬浮效果 */
    }

    .register-button {
        height: 44px;
        font-size: 16px;
        border-radius: 8px;
        margin-top: 24px;
    }

    .terms {
        margin-top: 16px;
        font-size: 12px;
        color: #999;
    }

    .terms a {
        font-size: 12px;
    }

    .login-link {
        margin-top: 16px;
        font-size: 14px;
    }

    /* 验证码弹窗优化 */
    .captcha-modal {
        background: rgba(0, 0, 0, 0.4);
    }

    .captcha-content {
        width: calc(100% - 48px);
        padding: 20px;
        border-radius: 12px;
    }

    .captcha-title {
        font-size: 18px;
    }

    .captcha-input {
        height: 44px;
        font-size: 14px;
        border-radius: 8px;
    }

    .captcha-buttons {
        gap: 12px;
    }

    .captcha-button {
        height: 44px;
        font-size: 14px;
        border-radius: 8px;
    }

    /* 错误提示优化 */
    .error-message {
        font-size: 12px;
        margin-top: 4px;
    }
}

/* 特小屏幕优化 */
@media screen and (max-width: 320px) {
    .register-right {
        padding: 16px 12px;
    }

    .form-title {
        font-size: 22px;
    }

    .form-input {
        height: 40px;
        font-size: 13px;
    }

    .get-code-button {
        padding: 0 8px;
        font-size: 11px;
    }

    .get-code-button {
        min-width: 90px;
        padding: 0 10px;
        font-size: 12px;
    }
}

/* 横屏优化 */
@media screen and (max-height: 480px) and (orientation: landscape) {
    .register-container {
        align-items: flex-start;
        padding: 16px;
    }

    .register-right {
        padding: 16px;
    }

    .form-header {
        margin-bottom: 16px;
    }

    .form-group {
        margin-bottom: 12px;
    }
} 