.container {
  padding: 24px;
  background-color: #f5f7fa;
  min-height: 100%;
}

.overviewCard {
  margin-bottom: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.balanceCard,
.pointsCard,
.totalCard {
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}

.statistic :global(.ant-statistic-title) {
  color: #666;
  font-size: 14px;
  margin-bottom: 12px;
}

.statistic :global(.ant-statistic-content) {
  color: #1a1a1a;
  font-size: 28px;
  font-weight: 600;
}

.recordsCard {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.tabs :global(.ant-tabs-nav) {
  margin-bottom: 24px;
}

.tabs :global(.ant-tabs-tab) {
  font-size: 16px;
  padding: 12px 0;
}

.statusSuccess {
  color: #52c41a;
}

.statusError {
  color: #ff4d4f;
}

.statusWarning {
  color: #faad14;
}

.statusInfo {
  color: #1890ff;
}

.statusDefault {
  color: #8c8c8c;
}

/* 响应式样式 */
@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .balanceCard,
  .pointsCard,
  .totalCard {
    padding: 16px;
    margin-bottom: 0;
  }

  .statistic :global(.ant-statistic-content) {
    font-size: 24px;
  }
}

.tableCard {
  margin: -24px;  /* 抵消 Tab 的内边距 */
}

.tableCard :global(.ant-card-body) {
  padding: 0;
}

.tableCard :global(.ant-card-extra) {
  padding: 16px 24px;
  margin: 0;
} 