.recharge-container {
    padding: 24px;
    background-color: #f0f2f5;
    min-height: 100vh;
}

.recharge-card {
    max-width: 800px;
    margin: 0 auto;
}

.balance-info {
    margin-bottom: 24px;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.balance-item {
    text-align: center;
}

.balance-item h3 {
    color: #666;
    margin-bottom: 8px;
}

.balance-amount {
    font-size: 24px;
    font-weight: bold;
    color: #1890ff;
}

.points-amount {
    font-size: 24px;
    font-weight: bold;
    color: #ff4d4f;
}

.balance-divider {
    width: 1px;
    height: 40px;
    background-color: #e8e8e8;
}

.amount-selection {
    margin-bottom: 24px;
}

.amount-options {
    width: 100%;
    margin-top: 16px;
}

.amount-option-button {
    width: 100%;
    height: auto !important;
    padding: 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.amount-option-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.amount-option-content .amount {
    font-size: 16px;
    font-weight: 500;
}

.amount-option-content .bonus-points {
    font-size: 12px;
    color: #ff4d4f;
    line-height: 1;
}

/* 选中状态下的样式 */
.ant-radio-button-wrapper-checked .bonus-points {
    color: #ff4d4f;
}

.custom-amount {
    margin-top: 16px;
}

.payment-info {
    margin: 24px 0;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 8px;
}

.payment-amount {
    font-size: 24px;
    font-weight: bold;
    color: #f5222d;
}

.recharge-notice {
    margin-top: 24px;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 8px;
}

.recharge-notice h4 {
    color: #666;
    margin-bottom: 8px;
}

.recharge-notice ul {
    padding-left: 20px;
    margin: 0;
    color: #666;
}

.recharge-notice li {
    margin-bottom: 4px;
}

.payment-method {
    margin: 24px 0;
}

.payment-options {
    width: 100%;
    margin-top: 16px;
}

.payment-option-button {
    width: 100%;
    height: 70px;
    padding: 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.payment-method-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: 100%;
}

.payment-method-content .anticon {
    font-size: 24px;
}

.payment-method-content span {
    font-size: 14px;
    line-height: 1;
}

/* 支付方式特定样式 */
.wechat-pay.ant-radio-button-wrapper-checked {
    border-color: #07C160 !important;
    background: #f6ffed !important;
}

.alipay-pay.ant-radio-button-wrapper-checked {
    border-color: #1677FF !important;
    background: #f0f5ff !important;
}

.qq-pay.ant-radio-button-wrapper-checked {
    border-color: #12B7F5 !important;
    background: #e6f7ff !important;
}

@media (max-width: 576px) {
    .recharge-container {
        padding: 12px;
    }

    .amount-option-button {
        height: 50px;
    }

    .payment-option-button {
        height: 60px;
        padding: 4px !important;
    }

    .payment-method-content .anticon {
        font-size: 20px;
    }

    .payment-method-content span {
        font-size: 12px;
    }

    .amount-option-content .amount {
        font-size: 14px;
    }

    .amount-option-content .bonus-points {
        font-size: 11px;
    }

    .balance-info {
        gap: 20px;
        padding: 15px;
    }

    .balance-amount,
    .points-amount {
        font-size: 20px;
    }

    .balance-item h3 {
        font-size: 14px;
    }

    .balance-divider {
        height: 30px;
    }
} 