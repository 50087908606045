.promotion-page {
    min-height: calc(100vh - 64px);
    margin-top: 64px;
    background: #0a0b1a;
    position: relative;
    overflow: hidden;
}

/* 动态背景 */
.promotion-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.gradient-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, 
        rgba(32, 17, 126, 0.8),
        rgba(67, 24, 155, 0.8),
        rgba(111, 30, 174, 0.8));
    animation: gradientMove 15s ease infinite;
}

.particles {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: 
        radial-gradient(circle, rgba(255,255,255,0.15) 1px, transparent 1px),
        radial-gradient(circle, rgba(255,255,255,0.1) 1px, transparent 1px);
    background-size: 50px 50px;
    background-position: 0 0, 25px 25px;
    animation: particleFloat 20s linear infinite;
}

/* 主要内容 */
.promotion-content {
    position: relative;
    z-index: 1;
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
    color: white;
}

/* 顶部英雄区 */
.hero-section {
    text-align: center;
    margin-bottom: 80px;
}

.hero-icon {
    font-size: 48px;
    color: #00f7ff;
    margin-bottom: 24px;
    animation: iconFloat 3s ease-in-out infinite;
}

.hero-section h1 {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 24px;
    background: linear-gradient(to right, #00f7ff, #9f00ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.9);
}

.dot {
    width: 6px;
    height: 6px;
    background: #00f7ff;
    border-radius: 50%;
}

/* 特点展示 */
.features-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    margin-bottom: 80px;
}

.feature-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-10px);
    border-color: #00f7ff;
}

.feature-icon {
    font-size: 36px;
    color: #00f7ff;
    margin-bottom: 20px;
}

.feature-card h3 {
    font-size: 20px;
    margin-bottom: 12px;
    color: white;
}

.feature-card p {
    color: rgba(255, 255, 255, 0.7);
    line-height: 1.6;
}

/* 推广链接区域 */
.link-section {
    margin-bottom: 80px;
}

.link-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 40px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.link-card h3 {
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
}

.link-input-group {
    display: flex;
    gap: 16px;
}

.link-input-group .ant-input {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
}

.link-input-group .ant-btn {
    background: linear-gradient(45deg, #00f7ff, #9f00ff);
    border: none;
}

/* 步骤说明 */
.steps-section {
    margin-bottom: 80px;
}

.steps-section h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 40px;
    background: linear-gradient(to right, #00f7ff, #9f00ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.steps-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 30px;
}

.step-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.step-card:hover {
    transform: translateY(-5px);
    border-color: #00f7ff;
}

.step-number {
    font-size: 36px;
    font-weight: 600;
    color: #00f7ff;
    margin-bottom: 16px;
}

.step-card h3 {
    font-size: 20px;
    margin-bottom: 12px;
    color: white;
}

.step-card p {
    color: rgba(255, 255, 255, 0.7);
}

/* 收益说明 */
.earnings-section {
    margin-bottom: 60px;
}

.earnings-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 40px;
    text-align: center;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.earnings-card h2 {
    font-size: 32px;
    margin-bottom: 40px;
    background: linear-gradient(to right, #00f7ff, #9f00ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.earnings-example {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-bottom: 24px;
}

.example-item {
    text-align: center;
}

.example-amount {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 8px;
}

.example-desc {
    color: rgba(255, 255, 255, 0.7);
}

.example-arrow {
    font-size: 24px;
    color: #00f7ff;
}

.highlight .example-amount {
    color: #00f7ff;
}

.earnings-note {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
}

/* 动画效果 */
@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes particleFloat {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50px);
    }
}

@keyframes iconFloat {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

/* 响应式调整 */
@media (max-width: 768px) {
    .promotion-content {
        padding: 40px 16px;
    }

    .hero-section h1 {
        font-size: 36px;
    }

    .hero-subtitle {
        font-size: 16px;
    }

    .link-input-group {
        flex-direction: column;
    }

    .earnings-example {
        flex-direction: column;
        gap: 20px;
    }
} 